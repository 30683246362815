import axios from 'axios';

const apiClient = axios.create({
  baseURL: '/api',
  withCredentials: false, // This is the default
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getTasks() {
    return apiClient.get('/tasks');
  },
  seen(id, type) {
    return apiClient.post('/seen', { id, type });
  },
  togglePrio(id, type) {
    return apiClient.post('/toggleprio', { id, type });
  },
  getState() {
    return apiClient.get('/state');
  },
  login(data) {
    return apiClient.post('/login', data);
  },
  logout() {
    return apiClient.get('/logout');
  },
  heartbeat() {
    return apiClient.get('/heartbeat');
  },
  createTask(data) {
    return apiClient.post('/createtask', data);
  },
  updateTask(data) {
    return apiClient.post('/updatetask', data);
  },
  deleteTask(data) {
    return apiClient.post('/deletetask', data);
  },
  resolveTask(data) {
    return apiClient.post('/resolvetask', data);
  },
  resolveConnectionTask(data) {
    return apiClient.post('/resolveconnectiontask', data);
  },
};
