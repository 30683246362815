<template>
  <div class="home">
      <v-card v-for="(group, idx) in groups" v-bind:key="idx" class="mb-4">
        <v-card-title>{{group.title}}</v-card-title>
        <v-list>
          <div v-for="(task, idx2) in group.tasks" v-bind:key="idx2">
            <v-list-item two-line>
              <v-list-item-avatar>
                <img src="img/jira.png" v-if="task.type === 'Jira'">
                <img src="img/logo.svg" v-else-if="task.type === 'Task'
                || task.type === 'Connection'">
                <img src="img/zentrale.png" v-else-if="task.type === 'Confluence'">
                <span v-else>{{task.type}}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{task.title}}</v-list-item-title>
                <v-list-item-subtitle>
                  <div v-if="task.type === 'Task'
                  || task.type === 'Connection'">{{task.subtitle}}</div>
                  <div v-if="task.tags">Tags: {{task.tags}}</div>
                  <div v-if="task.duedate">Due: {{task.duedate}}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :href="task.link" v-if="task.link" target="_blank">open</v-btn>
                <v-btn @click.prevent="setSeen(task.id, task.type)"
                       v-if="group.title === 'new'">seen</v-btn>
                <v-btn @click.prevent="togglePrio(task.id, task.type)"
                v-if="task.type !== 'Connection'"
                >prio</v-btn>
                <v-btn @click.prevent="editTask(task)"  v-if="task.type === 'Task'">edit</v-btn>
                <v-btn @click.prevent="resolveConnectionTask(task)"
                       v-if="task.type === 'Connection'">fixed!</v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="idx2 < group.tasks.length-1"></v-divider>
          </div>
        </v-list>
      </v-card>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      persistent
      v-model="showAddTask"
    >
      <template>
        <v-card>
          <v-toolbar
            color="primary"
            dark
          ><span v-if="edittaskid === null">New Task</span><span v-else>Edit Task</span></v-toolbar>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="title" outlined v-model="newtasktitle"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="text" outlined v-model="newtasktext"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menu"
                      v-model="datepickermenu"
                      :close-on-content-click="false"
                      :return-value.sync="newtaskdate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newtaskdate"
                          label="due"
                          prepend-icon="fa-calendar"
                          readonly
                          clearable
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newtaskdate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="datepickermenu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(newtaskdate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-select outlined :items="[
                      { 'val': 5, 'name': 'very high' },
                      { 'val': 4, 'name': 'high' },
                      { 'val': 3, 'name': 'normal' },
                      { 'val': 2, 'name': 'low' },
                      { 'val': 1, 'name': 'very low' },
                    ]"
                    item-value="val"
                    item-text="name"
                    v-model="newtaskpriority"
                    label="priority"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              @click="closeNewTaskFormAndAbort"
              :loading="newtasksaving"
            >never mind...</v-btn>
            <v-btn
              color="primary"
              :loading="newtasksaving"
              @click="closeNewTaskFormAndSave"
              v-if="edittaskid == null"
            >let's do this!</v-btn>
            <v-btn
              color="red"
              :loading="newtasksaving"
              @click="closeEditTaskFormAndDelete"
              v-if="edittaskid !== null"
            >it solved itself!</v-btn>
            <v-btn
              color="green"
              :loading="newtasksaving"
              @click="closeEditTaskFormAndResolve"
              v-if="edittaskid !== null"
            >i'm done, huzzah!</v-btn>
            <v-btn
              color="primary"
              :loading="newtasksaving"
              @click="closeEditTaskFormAndSave"
              v-if="edittaskid !== null"
            >looks way better now!</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

import BackendService from '../services/BackendService';

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      groups: [],
      datepickermenu: false,
      newtaskdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10),
      newtasktitle: '',
      newtasktext: '',
      newtaskpriority: 3,
      newtasksaving: false,
      edittaskid: null,
    };
  },
  props: {
    showAddTask: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (window.dashboardtimer !== undefined) {
      window.clearInterval(window.dashboardtimer);
    }
    window.dashboardtimer = window.setInterval(this.update, 20000);
    this.update();
  },
  methods: {
    editTask(task) {
      this.newtasktitle = task.title;
      this.newtasktext = task.subtitle;
      this.newtaskdate = task.duedateiso;
      this.newtaskpriority = task.priority;
      this.edittaskid = task.id;
      this.$emit('toggleTaskForm');
    },
    closeNewTaskFormAndAbort() {
      this.$emit('toggleTaskForm');
      this.resetNewTaskData();
    },
    closeNewTaskFormAndSave() {
      this.newtasksaving = true;
      BackendService.createTask({
        title: this.newtasktitle,
        text: this.newtasktext,
        priority: this.newtaskpriority,
        due: this.newtaskdate,
      }).then((data) => {
        this.newtasksaving = false;
        this.$emit('toggleTaskForm');
        this.resetNewTaskData();
        this.groups = data.data;
      });
    },
    closeEditTaskFormAndSave() {
      this.newtasksaving = true;
      BackendService.updateTask({
        id: this.edittaskid,
        title: this.newtasktitle,
        text: this.newtasktext,
        priority: this.newtaskpriority,
        due: this.newtaskdate,
      }).then((data) => {
        this.newtasksaving = false;
        this.$emit('toggleTaskForm');
        this.resetNewTaskData();
        this.groups = data.data;
      });
    },
    closeEditTaskFormAndDelete() {
      this.newtasksaving = true;
      BackendService.deleteTask({
        id: this.edittaskid,
      }).then((data) => {
        this.newtasksaving = false;
        this.$emit('toggleTaskForm');
        this.resetNewTaskData();
        this.groups = data.data;
      });
    },
    resolveConnectionTask(task) {
      BackendService.resolveConnectionTask({
        id: task.id,
      }).then((data) => {
        this.groups = data.data;
      });
    },
    closeEditTaskFormAndResolve() {
      this.newtasksaving = true;
      BackendService.resolveTask({
        id: this.edittaskid,
      }).then((data) => {
        this.newtasksaving = false;
        this.$emit('toggleTaskForm');
        this.resetNewTaskData();
        this.groups = data.data;
      });
    },
    resetNewTaskData() {
      this.newtaskdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10);
      this.newtasktitle = '';
      this.newtasktext = '';
      this.newtaskpriority = 3;
      this.edittaskid = null;
    },
    update() {
      BackendService.getTasks().then((data) => {
        this.groups = data.data;
      });
    },
    setSeen(id, type) {
      BackendService.seen(id, type).then((data) => {
        this.groups = data.data;
      });
    },
    togglePrio(id, type) {
      BackendService.togglePrio(id, type).then((data) => {
        this.groups = data.data;
      });
    },
  },
};
</script>
