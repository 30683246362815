<template>
  <v-app>
    <v-app-bar
      app
      v-if="$store.state.loggedin"
    >
      <v-btn @click.prevent="toggleTaskForm">new Task</v-btn>
      <v-spacer></v-spacer>

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>fa-user</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main
      v-if="$store.state.loggedin"
    >
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view :showAddTask="showAddTask" @toggleTaskForm="toggleTaskForm"></router-view>
      </v-container>
    </v-main>
    <Spinner v-if="!$store.state.gotstate" />
    <Login  v-if="$store.state.gotstate && !$store.state.loggedin" />
  </v-app>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

.v-application .display-1 {
  font-family: 'Bungee', cursive !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import Spinner from './components/Spinner.vue';
import Login from './components/Login.vue';
import BackendService from './services/BackendService';

export default {
  components: { Login, Spinner },
  data() {
    return {
      showAddTask: false,
    };
  },
  methods: {
    toggleTaskForm() {
      this.showAddTask = !this.showAddTask;
    },
    logout() {
      BackendService.logout().then((data) => {
        this.$store.commit('upadteState', data.data);
      });
    },
  },
  created() {
    if (document.ve_heartbeat !== undefined) {
      window.clearInterval(document.ve_heartbeat);
    }
    document.ve_heartbeat = window.setInterval(() => {
      BackendService.heartbeat().then((data) => {
        if (data.data.loggedin !== undefined) {
          this.$store.commit('updateLoginState', data.data.loggedin);
        }
      });
    }, 30000);
    BackendService.getState().then((data) => {
      this.$store.commit('upadteState', data.data);
    });
  },
};

</script>
