import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gotstate: false,
    loggedin: false,
  },
  mutations: {
    upadteState(state, payload) {
      state.gotstate = true;
      state.loggedin = payload.loggedin;
    },
    updateLoginState(state, payload) {
      state.loggedin = payload;
    },
  },
  actions: {
  },
  modules: {
  },
});
